import React, {useEffect, useRef} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './BrowseArchetypesScreen.scss';
import ArchetypeTable from '../ArchetypeTable/ArchetypeTable';

let isFirstRender = true;
function BrowseArchetypesScreen({visibilityToggle}) {
    const browseArchetypesScreenRef = useRef(null);

    async function toggleScreen() {
        if (browseArchetypesScreenRef.current.className === 'browse-archetypes-screen-container') {
            browseArchetypesScreenRef.current.className = 'browse-archetypes-screen-container open';
        } else {
            browseArchetypesScreenRef.current.className = 'browse-archetypes-screen-container';
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
        } else {
            toggleScreen();
        }
    }, [visibilityToggle]);

    return (
        <div className="browse-archetypes-screen-container" ref={browseArchetypesScreenRef}>
            <div className="content-container-browse-archetypes-screen">
                <div className="close-button-container" onClick={() => toggleScreen()}>
                    <CloseIcon fontSize="large" classes={{root: 'close-icon'}} />
                </div>

                <div className="table-container">
                    <ArchetypeTable />
                </div>
            </div>
        </div>
    );
}

export default BrowseArchetypesScreen;
