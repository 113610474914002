import React, { useState, useEffect, useRef } from 'react';
import './MetadataScreen.scss';
import Button from '../Button/Button';
import store from '../../store';

let isFirstRender = true;
let previousVisabilityToggle;
function MetadataScreen({ visibilityToggle }) {
    const [isOpen, setIsOpen] = useState(false);
    const metadataScreenRef = useRef(null);

    function togglePopUp() {
        if (metadataScreenRef.current.className === 'tabular-screen') {
            metadataScreenRef.current.className = 'tabular-screen open';
            setIsOpen(true);
        } else {
            metadataScreenRef.current.className = 'tabular-screen';
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
            previousVisabilityToggle = visibilityToggle;
        } else {
            if (previousVisabilityToggle !== visibilityToggle) {
                previousVisabilityToggle = visibilityToggle;
                togglePopUp();
            }
        }
    }, [visibilityToggle]);

    return (
        <div className="tabular-screen" ref={metadataScreenRef}>
            {(() => {
                if (isOpen) {
                    return (
                        <div className="content-container">
                            <div className="buttons-row">
                                <div className="back-button">
                                    <Button
                                        text="Back"
                                        onClick={() => {
                                            store.dispatch({ type: 'progress/setMetadataScreenToggle', payload: !store.getState().metadataScreenToggle });
                                        }}
                                    ></Button>
                                </div>

                                <div className="spacer"></div>
                            </div>

                            <div className="metadata-input-rows">
                                <div className="switch-button">
                                    <Button
                                        text="Save"
                                        onClick={() => {
                                            store.dispatch({ type: 'progress/setMetadataScreenToggle', payload: !store.getState().metadataScreenToggle });
                                        }}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    );
                }
            })()}
        </div>
    );
}

export default MetadataScreen;
