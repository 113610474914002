import {React, useState, useEffect} from 'react';
import './Admin.scss';
import store from './store';
import Button from './components/Button/Button';
import axios from 'axios';
import AlertBox from './components/AlertBox/AlertBox';
import InformationBox from './components/InformationBox/InformationBox';
import * as xlsx from 'xlsx';

let fileReader;
function Admin() {
    const [mutationDatabaseFileName, setmutationDatabaseFileName] = useState('');
    const [updateMutationsDatabaseStatus, setMutationsDatabaseStatus] = useState('File not selected');
    const [resetMutationsDatabaseStatus, setResetMutationsDatabaseStatus] = useState('Not reset');
    const [isAdmin, setIsAdmin] = useState(false);

    // function generateReferences(referenceString) {
    //     referenceString = referenceString.substring(1, referenceString.length - 1);
    //     let splitStrings = referenceString.split(',');
    //     let references = [];
    //     const chunkSize = 2;
    //     for (let i = 0; i < splitStrings.length; i += chunkSize) {
    //         const chunk = splitStrings.slice(i, i + chunkSize);
    //         references.push('(' + chunk.join(',').trim() + ')');
    //     }

    //     return references;
    // }

    async function checkIsLoggedIn() {
        try {
            const response = await axios.get('api/v1/check_login');
            if (response.data.logged_in) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
            return false;
        }
    }

    async function checkIsAdmin() {
        try {
            const response = await axios.get('api/v1/check_admin');
            if (response.data.admin) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
            return false;
        }
    }

    async function updateEmail() {
        try {
            const response = await axios.get('api/v1/get_email');
            store.dispatch({type: 'account/setAccountEmail', payload: response.data.email});
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
        }
    }

    function resetMutationsDatabase() {
        try {
            setResetMutationsDatabaseStatus('Processing reset...');
            axios.post('api/v1/post_clear_mutations_database').then((response) => {
                setResetMutationsDatabaseStatus('Reset successful');
            });
        } catch (error) {
            setResetMutationsDatabaseStatus(`Reset failed: ${error}`);
        }
    }

    function handleFileInput({target}) {
        let file = target.files[0];
        setmutationDatabaseFileName(file.name);
        setMutationsDatabaseStatus('File selected');
        fileReader = new FileReader();
        //fileReader.onloadend = onFileReadComplete;
        fileReader.onload = (e) => {
            //load in the sheet file
            //find references for each one
            //fail if format wrong or something without NA doesn't have a refernce url found in references worksheet
            //otherwise store all new mutations in a json (newAllMutations)
            //one list for mutations already in the mutations database and one for new mutations (to be displayed)
            //when submitted / confirmed (somewhere else) then the new mutations will be sent to the server to update the database

            //notes for sheet columns:
            //mutation_reference could be NA (doesn't make it invalid)
            //mutation can't be NA
            //alt_species_homology could be NA (doesn't make it invalid) as long as alt_species_homology_reference is also NA
            //having a NA refernce is fine but having a reference without corresponding link / full reference is not fine

            //need abr, find_at, full_ref

            const data = e.target.result;
            const workbook = xlsx.read(data, {type: 'array'});
            //const sheetName = workbook.SheetNames[0];
            const worksheetMutations = workbook.Sheets['Mutations'];
            const worksheetReferences = workbook.Sheets['References'];
            const workbookJson = {
                mutations: xlsx.utils.sheet_to_json(worksheetMutations),
                references: xlsx.utils.sheet_to_json(worksheetReferences),
            };

            setMutationsDatabaseStatus('Processing update...');
            axios
                .post('api/v1/post_update_mutations_database', workbookJson)
                .then((response) => {
                    setMutationsDatabaseStatus(`Update sucessful with ${response.data.number_of_mutations} mutations`);
                })
                .catch((error) => {
                    setMutationsDatabaseStatus('Update failed due to invalid file or reference format');
                });
        };
        fileReader.readAsArrayBuffer(file);
        //fileReader.readAsText(file);

        target.value = '';
    }

    useEffect(() => {
        checkIsLoggedIn().then((result) => {
            store.dispatch({type: 'account/setIsLoggedIn', payload: result});

            if (result) {
                updateEmail();
            }
        });
        checkIsAdmin().then((result) => {
            if (result) {
                setIsAdmin(true);
            }
        });
    }, []);

    return (
        <div className="admin-container">
            <div className="content-container">
                <div className="button-row">
                    <div className="step-buttons">
                        <Button text="Home" dark onClick={() => (window.location = '/')}></Button>
                    </div>
                    <AlertBox></AlertBox>
                </div>

                {!isAdmin ? (
                    <div style={{paddingTop: '1rem'}}>Access denied, login on the home page using an admin account</div>
                ) : (
                    <>
                        <h1>Admin Panel</h1>
                        <div className="mutations-database-container">
                            <h2>Mutations Database</h2>
                            <p>
                                The following options regard the database containing the metadata of known mutations.
                                <br />A confirmation box will appear before changes are confirmed.
                            </p>

                            <div className="action-container reset">
                                <h3 className="action-title">Reset</h3>
                                <div className="information-container">
                                    <InformationBox
                                        text={
                                            <div>Reset the database of mutations in the case of a mistake, keep a copy of the spreadsheet file to reupload</div>
                                        }></InformationBox>
                                </div>

                                <div className="button-row">
                                    <Button text="RESET" onClick={() => resetMutationsDatabase()}></Button>
                                </div>
                                <p>
                                    Current Status: <span>{resetMutationsDatabaseStatus}</span>
                                </p>
                            </div>

                            <div className="action-container update">
                                <h3 className="action-title">Update</h3>
                                <div className="information-container">
                                    <InformationBox
                                        text={
                                            <div>
                                                Update (replace) the database of mutations by uploading a spreadsheet (.xlsx, .ods) file, a sample can be found{' '}
                                                <a
                                                    href="https://docs.google.com/spreadsheets/d/13OKIoO8Vwn1XZ8bntleWyK6zaa9CFj_wY09kqwo3qfA/edit?usp=sharing"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    here
                                                </a>
                                            </div>
                                        }></InformationBox>
                                </div>

                                <div className="upload-row">
                                    <div className="upload-file">
                                        <label class="custom-file-upload">
                                            <input type="file" accept=".ods, .xlsx" onChange={(e) => handleFileInput(e)} />
                                            Upload
                                        </label>
                                    </div>
                                    <div className="spacer"></div>
                                </div>
                                <p>
                                    Selected file: <span>{mutationDatabaseFileName}</span>
                                </p>
                                <p>
                                    Current Status: <span>{updateMutationsDatabaseStatus}</span>
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Admin;
