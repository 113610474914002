import React, {useState, useEffect, useRef} from 'react';
import './TabularScreen.scss';
import Button from '../Button/Button';
import SequenceTable from '../SequenceTable/SequenceTable';
import store from '../../store';

let isFirstRender = true;
let previousVisabilityToggle;
function TabularScreen({visibilityToggle}) {
    const [isOpen, setIsOpen] = useState(false);
    const tabularScreenRef = useRef(null);

    function togglePopUp() {
        if (tabularScreenRef.current.className === 'tabular-screen') {
            tabularScreenRef.current.className = 'tabular-screen open';
            setIsOpen(true);
        } else {
            tabularScreenRef.current.className = 'tabular-screen';
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
            previousVisabilityToggle = visibilityToggle;
        } else {
            if (previousVisabilityToggle !== visibilityToggle) {
                previousVisabilityToggle = visibilityToggle;
                togglePopUp();
            }
        }
    }, [visibilityToggle]);

    return (
        <div className="tabular-screen" ref={tabularScreenRef}>
            {(() => {
                if (isOpen) {
                    return (
                        <div className="content-container">
                            <div className="buttons-row">
                                <div className="back-button">
                                    <Button
                                        text="Back"
                                        onClick={() => {
                                            store.dispatch({type: 'progress/setTabularScreenToggle', payload: !store.getState().tabularScreenToggle});
                                        }}></Button>
                                </div>

                                <div className="spacer"></div>

                                <div className="switch-button">
                                    <Button
                                        text="Switch to Alignment"
                                        onClick={() => {
                                            store.dispatch({type: 'progress/setTabularScreenToggle', payload: !store.getState().tabularScreenToggle});
                                            store.dispatch({type: 'progress/setAlignmentScreenToggle', payload: !store.getState().alignmentScreenToggle});
                                        }}></Button>
                                </div>
                            </div>

                            <div className="table-row">
                                <SequenceTable></SequenceTable>
                            </div>
                        </div>
                    );
                }
            })()}
        </div>
    );
}

export default TabularScreen;
