import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import store from '../../store';
import './AlertBox.scss';

function AlertBox() {
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState('');

    store.subscribe(() => {
        if (store.getState()['alertErrorString'] != null) {
            setOpen(true);
            setText(store.getState()['alertErrorString']);
        }
    });

    return (
        <div className="alert-box-container">
            <Box sx={{width: '100%'}}>
                {open && (
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                    store.dispatch({type: 'progress/setAlertErrorString', payload: null});
                                }}>
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{mb: 2}}>
                        {text}
                    </Alert>
                )}
            </Box>
        </div>
    );
}

export default AlertBox;
