import { React, useState, useEffect } from 'react';

function SettingsInput({ min, max, placeholder, setting, settingsCallback }) {
    const [value, setValue] = useState(0);

    useEffect(() => {
        setValue(placeholder);
        setting.value = placeholder;
        settingsCallback(setting);
    }, []);

    const onChange = e => {
        setValue(e.target.value);
        setting.value = e.target.value;
        settingsCallback(setting);
    };

    return (
        <div>
            <input className="index-input" type="number" min={min} max={max} placeholder={placeholder} value={value} onChange={onChange} />
        </div>
    );
}

export default SettingsInput;
