import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Admin from './Admin';
import './index.scss';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

ReactDOM.render(
    <Router>
        <React.StrictMode>
            <Routes>
                <Route path="/admin" element={<Admin></Admin>}></Route>
                <Route path="/" element={<App></App>}></Route>
            </Routes>
        </React.StrictMode>
    </Router>,
    document.getElementById('root'),
);
