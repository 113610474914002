import React, {useEffect, useRef} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './TutorialScreen.scss';

let isFirstRender = true;
function TutorialScreen({visibilityToggle}) {
    const tutorialScreenRef = useRef(null);

    async function toggleScreen() {
        if (tutorialScreenRef.current.className === 'tutorial-screen-container') {
            tutorialScreenRef.current.className = 'tutorial-screen-container open';
        } else {
            tutorialScreenRef.current.className = 'tutorial-screen-container';
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
        } else {
            toggleScreen();
        }
    }, [visibilityToggle]);

    return (
        <div className="tutorial-screen-container" ref={tutorialScreenRef}>
            <div className="content-container-tutorial-screen">
                <div className="close-button-container" onClick={() => toggleScreen()}>
                    <CloseIcon fontSize="large" classes={{root: 'close-icon'}} />
                </div>

                <h1>Tutorial</h1>

                <div className="text">
                    <p>
                        Before you start aligning your sequences with FRAST, you need to select the alignment mode. FRAST has 3 "archetype input" modes:
                        <br />
                        <br />
                        <div className="indent">
                            1. vs a reference sequence (recommended) - this allows you to choose one of the achetype sequences for fungicide resistance proteins
                            described in Mair et al 2016, Pest. Manag. Sci 72(8):1449-59.
                            <br />
                            <br />
                            2. vs a user-defined custom sequence - note: this mode will report mutations but not be able to provide information where known
                            fungicide resistance sites have been detected.
                            <br />
                            <br />
                            3. automatic - this mode will search user inputs vs all archetypes, to assist in choosing the correct archetype sequence for option
                            (1).
                        </div>
                        <br />
                        <br />
                        Next you will need to provide one or more amino acid sequences in FASTA format, by either pasting directly into the dialog box or
                        selection "upload file" and navigating to a fasta file on your computer.
                        <br />
                        <br />
                        Then you wil be able to view the results of FRAST, which can be visualised as either an alignment or in tabular form. To select between
                        these two output types, select either "alignment" or "tabular", then "view output". Optionally, you can download the alignment result
                        produced by MAFFT in the alignment view, or the tabular output in CSV format in the tabular view.
                        <br />
                        <br />
                        Proceeding to the next "Session" section will allow you to save your session for future access. In order to enable this feature, you
                        must first login by clicking on "login" at the top right. Users can log in to FRAST via either a google or microsoft account.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default TutorialScreen;
