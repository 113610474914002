import {React, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PaperButton from '../PaperButton/PaperButton';
import axios from 'axios';
import './MutationsTable.scss';

const columns = [
    {id: 'name', label: 'Sequence (Archetype)', minWidth: 50, maxWidth: 50},
    {
        id: 'mutationCode',
        label: 'Mutation Code',
        minWidth: 60,
        maxWidth: 60,
        align: 'left',
    },
    {
        id: 'altSpeciesHomology',
        label: 'Alt Species Homology',
        minWidth: 60,
        maxWidth: 60,
        align: 'left',
    },
    {
        id: 'researchPapers',
        label: 'Research Papers',
        minWidth: 200,
        maxWidth: 200,
        align: 'left',
    },
];

function createData(name, mutationCode, altSpeciesHomology, researchPapers) {
    return {
        name,
        mutationCode,
        altSpeciesHomology,
        researchPapers,
    };
}

let rows = [];

export default function MutationsTable() {
    //const [currentSequences, setCurrentSequences] = useState(Object.keys(alignedSequences).length !== 0 ? alignedSequences['sequences'] : []);

    async function setAllMutations() {
        try {
            const response = await axios.get('api/v1/get_mutations_database');
            let allMutations = response.data.mutations;
            // console.log(allMutations);
            if (allMutations) {
                if (allMutations.length !== 0) {
                    // clear previous data first
                    rows = [];
                    allMutations.forEach((mutation) => {
                        let referencesCombined = mutation['mutation_references'].concat(mutation['alt_species_homology_references']);
                        let papersDiv = (
                            <div className="research-papers-col">
                                <div className="papers-list">
                                    {referencesCombined.map((paper) => {
                                        return paper['ref_short'] !== 'NA' ? (
                                            <PaperButton text={paper['ref_short']} link={paper['ref_url']}></PaperButton>
                                        ) : null;
                                    })}
                                </div>
                            </div>
                        );
                        rows.push(createData(mutation.archetype, mutation.mutation, mutation.alt_species_homology, papersDiv));
                    });
                }
            }
        } catch (error) {
            return [];
        }
    }

    setAllMutations();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="mutations-table-container-class">
            <Paper sx={{width: '100%', overflow: 'hidden', border: '0.1rem solid black', borderRadius: '0.25rem'}}>
                <TableContainer
                    sx={{
                        maxHeight: '75vh',
                        minHeight: '75vh',
                        '& .MuiTableRow-root:hover': {
                            backgroundColor: '#ffffff',
                        },
                    }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
