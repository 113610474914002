import {React, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import store from '../../store';
import PaperButton from '../PaperButton/PaperButton';
import './SequenceTable.scss';

const columns = [
    {id: 'name', label: 'Sequence', minWidth: 50, maxWidth: 50},
    {
        id: 'mutationCode',
        label: 'Mutation Code',
        minWidth: 60,
        maxWidth: 60,
        align: 'left',
    },
    {
        id: 'indexArchetype',
        label: 'Index (Archetype)',
        minWidth: 60,
        maxWidth: 60,
        align: 'left',
    },
    {
        id: 'indexSequence',
        label: 'Index (Sequence)',
        minWidth: 60,
        maxWidth: 60,
        align: 'left',
    },
    {
        id: 'researchPapers',
        label: 'Research Papers',
        minWidth: 200,
        maxWidth: 200,
        align: 'left',
    },
];

function createData(name, mutationCode, indexArchetype, indexSequence, researchPapers) {
    return {
        name,
        mutationCode,
        indexArchetype,
        indexSequence,
        researchPapers,
    };
}

let rows = [];

export default function SequenceTable() {
    let alignedSequences = store.getState()['alignedSequences'];
    const [currentSequences, setCurrentSequences] = useState(Object.keys(alignedSequences).length !== 0 ? alignedSequences['sequences'] : []);

    //setLocation(store.getState().location)
    store.subscribe(() => {
        alignedSequences = store.getState()['alignedSequences'];
        if (Object.keys(alignedSequences).length !== 0) {
            const alignedSequencesData = alignedSequences['sequences'];
            setCurrentSequences(alignedSequencesData);
        }
    });

    if (currentSequences) {
        if (currentSequences.length !== 0) {
            // clear previous data first
            rows = [];
            currentSequences.forEach((sequence) => {
                sequence['mutations'].forEach((mutation) => {
                    let papersDiv = (
                        <div className="research-papers-col">
                            <div className="papers-list">
                                {mutation['papers'].map((paper) => {
                                    return <PaperButton text={paper['abr']} link={paper['find_at']}></PaperButton>;
                                })}
                            </div>
                        </div>
                    );
                    rows.push(createData(sequence.name, mutation.code, mutation.index_archetype, mutation.index_sequence, papersDiv));
                });
            });
        }
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{width: '100%', overflow: 'hidden', border: '0.1rem solid black', borderRadius: '0.25rem'}}>
            <TableContainer
                sx={{
                    maxHeight: 560,
                    minHeight: 559,
                    '& .MuiTableRow-root:hover': {
                        backgroundColor: '#ffffff',
                    },
                }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
