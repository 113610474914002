import {React, useState} from 'react';
import './SequenceInput.scss';
import store from '../../store';
import InformationBox from '../InformationBox/InformationBox';
import MetadataScreen from '../MetadataScreen/MetadataScreen';
import TextBox from '../TextBox/TextBox';

let fileReader;
let persistantText = '';
function SequenceInput() {
    const [metadataScreenToggle, setMetadataScreenToggle] = useState(store.getState().metadataScreenToggle);
    const [persistentFastaString, setPersistentFastaString] = useState(persistantText);

    store.subscribe(() => {
        if (store.getState().fastaString !== null) {
            persistantText = store.getState().fastaString;
            setPersistentFastaString(store.getState().fastaString);
        }
        // console.log('store ' + store.getState().fastaString.substring(0, 20));
        // if (persistentFastaString === null) {
        //     console.log('persistentFastaString ' + 'null');
        // } else {
        //     console.log('persistentFastaString ' + persistentFastaString.substring(0, 20));
        // }
    });

    function setFastaString(e) {
        persistantText = e;
        store.dispatch({type: 'sequences/setFastaString', payload: e});
        setPersistentFastaString(e);
    }

    function onFileReadComplete() {
        const fileText = fileReader.result;
        persistantText = fileText;
        store.dispatch({type: 'sequences/setFastaString', payload: fileText});
        setPersistentFastaString(fileText);
    }

    function handleFileInput({target}) {
        let file = target.files[0];
        fileReader = new FileReader();
        fileReader.onloadend = onFileReadComplete;
        fileReader.readAsText(file);
        target.value = '';
    }

    return (
        <div className="sequence-input-container">
            <MetadataScreen visibilityToggle={metadataScreenToggle}></MetadataScreen>
            <InformationBox text={'Upload or manually input the test sequences in FASTA format'}></InformationBox>
            <div className="sequence-input-content">
                <div className="sequence-input">
                    <h1 className="title">Sequence Input</h1>
                    <div className="upload-input">
                        <div className="upload-row">
                            <div className="upload-file">
                                <label class="custom-file-upload">
                                    <input type="file" accept=".fasta, .FASTA, .txt" onChange={(e) => handleFileInput(e)} />
                                    Upload File
                                </label>
                            </div>
                            <div className="spacer"></div>
                        </div>
                        <div className="input-row">
                            <TextBox
                                text={persistentFastaString}
                                placeHolder={'Input test sequences here in FASTA format here'}
                                persistantText={persistentFastaString}
                                callback={(e) => {
                                    setFastaString(e);
                                }}></TextBox>
                        </div>
                        <div className="metadata-text">
                            How to include metadata?
                            <div className="hover-text">
                                {'>'}seqID|date|location|host|pathogen <br /> date is YYYYMMDD or YYYYMM or YYYY <br /> location is Country:City/Town or Country
                                or lat:long (with geonames.org as a guide for names) <br />
                                host/pathogen are species names recognised in NCBI taxonomy ftp://ftp.ncbi.nlm.nih.gov/pub/taxonomy/taxdump.tar.gz
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SequenceInput;
