import axios from 'axios';
import React, {useEffect, useRef} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './AccountScreen.scss';
import Button from '../Button/Button';
import store from '../../store';

let isFirstRender = true;
function AccountScreen({visibilityToggle}) {
    const accountScreenRef = useRef(null);

    async function toggleScreen() {
        if (accountScreenRef.current.className === 'account-screen') {
            accountScreenRef.current.className = 'account-screen open';
        } else {
            accountScreenRef.current.className = 'account-screen';
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
        } else {
            toggleScreen();
        }
    }, [visibilityToggle]);

    async function logoutButtonClicked() {
        try {
            window.location = 'logout';
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
        }
    }

    async function deleteAccountButtonClicked() {
        try {
            const response = await axios.post('delete_account');
            //setAlertCallback('Account deleted', false);
            window.location = 'logout';
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
        }
    }

    return (
        <div className="account-screen" ref={accountScreenRef}>
            <div className="content-container-account-screen">
                <div className="close-button-container" onClick={() => toggleScreen()}>
                    <CloseIcon fontSize="large" classes={{root: 'close-icon'}} />
                </div>

                <img className="nav-logo" src="images/frast_logo.svg" alt="" />

                <p className="account-email">{store.getState().accountEmail}</p>

                <div className="account-buttons">
                    <Button
                        text="Logout"
                        onClick={() => {
                            logoutButtonClicked();
                        }}></Button>
                    <Button
                        text="Delete Account"
                        onClick={() => {
                            deleteAccountButtonClicked();
                        }}></Button>
                </div>

                <p>
                    By deleting your account, you acknowledge that <br></br> your saved data will be permanently deleted
                </p>
            </div>
        </div>
    );
}

export default AccountScreen;
