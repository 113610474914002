import {React, useState, useEffect} from 'react';
import './ArchetypeInput.scss';
import store from '../../store';
import axios from 'axios';
import InformationBox from '../InformationBox/InformationBox';
import InformationButton from '../InformationButton/InformationButton';
import SequenceSelector from '../SequenceSelector/SequenceSelector';
import TextBox from '../TextBox/TextBox';
import Button from '../Button/Button';

let customArchetypeSequence = '';
let blastSearchSequence = '';
let persistentIsBlastSearch = false;
let databaseIndex = 0;
let blastIndex = 0;
function ArchetypeInput() {
    const [databaseArchetypes, setDatabaseArchetypes] = useState([{name: '', sequence: ''}]);
    const [selectedInputMethod, setSelectedInputMethod] = useState(0);
    const [selectedArchetype, setSelectedArchetype] = useState({name: '', sequence: ''});
    //let blastSearchSequence = '';
    const [isBlastSearch, setIsBlastSearch] = useState(persistentIsBlastSearch);

    useEffect(() => {
        if (store.getState().archetypes == null) {
            axios.get(`/api/v1/get_archetypes_database`).then((response) => {
                setDatabaseArchetypes(response.data.archetypes);
                store.dispatch({type: 'sequences/setArchetypes', payload: response.data.archetypes});
                setSelectedArchetype(response.data.archetypes[0]);
                store.dispatch({type: 'sequences/setArchetype', payload: response.data.archetypes[0]});
            });
        } else {
            setDatabaseArchetypes(store.getState().archetypes);
        }

        if (store.getState().archetype != null) {
            setSelectedArchetype(store.getState().archetype);
        }

        setSelectedInputMethod(store.getState().archetypeInputMethod);
        customArchetypeSequence = store.getState().customArchetype;
    }, []);

    store.subscribe(() => {
        setSelectedInputMethod(store.getState().archetypeInputMethod);
    });

    function setCustomArchetype(inputString) {
        customArchetypeSequence = inputString;

        store.dispatch({type: 'sequences/setCustomArchetype', payload: inputString});

        let archetype = {name: '', sequence: ''};

        if (inputString[0] === '>') {
            archetype['name'] = inputString.slice(1, inputString.indexOf('\n'));
            archetype['sequence'] = inputString.slice(inputString.indexOf('\n') + 1, inputString.length);
        }

        if (archetype['name'].length < 1 || archetype['sequence'].length < 1) {
            //setAlertCallback('Archetype format invalid', true);
            store.dispatch({type: 'sequences/setArchetype', payload: null});
        } else {
            store.dispatch({type: 'sequences/setArchetype', payload: archetype});
            //setAlertCallback(`Archetype set as ${archetype['name']}`, false);
        }
    }

    async function doBlastSearch() {
        axios.post(`/api/v1/post_blast_search`, blastSearchSequence).then((response) => {
            store.dispatch({type: 'sequences/setBlastSequences', payload: response.data.blast_sequences});
            setIsBlastSearch(true);
            persistentIsBlastSearch = true;
        });
    }

    function handleInputMethodClick(index) {
        store.dispatch({type: 'progress/setLocation', payload: {step: 0, subStep: index}});
        setSelectedInputMethod(index);
        store.dispatch({type: 'sequences/setArchetypeInputMethod', payload: index});
    }

    function setArchetype(sequence) {
        store.dispatch({type: 'sequences/setArchetype', payload: {name: sequence.name, sequence: sequence.sequence}});
        setSelectedArchetype({name: sequence.name, sequence: sequence.sequence});
    }

    function setDatabaseIndexCallback(e) {
        databaseIndex = e;
    }

    function setBlastIndexCallback(e) {
        blastIndex = e;
    }

    return (
        <div className="archetype-input-container">
            <InformationBox text={'Choose a method to input an archetype, then select or input the relevant archetype'}></InformationBox>

            <div className="archetype-input-content">
                <div className="input-method">
                    <h1 className="title">Input Method</h1>
                    <div className="input-method-buttons">
                        <InformationButton
                            title="Reference"
                            description="Choose which fungicide resistance gene sequence to search from a list of archetypes provided in the database"
                            onClick={() => handleInputMethodClick(0)}
                            selected={selectedInputMethod === 0}></InformationButton>
                        <InformationButton
                            title="Custom"
                            description="Manually input an archetype sequence (FRAST will not be able to report known fungicide resistance mutations)"
                            onClick={() => handleInputMethodClick(1)}
                            selected={selectedInputMethod === 1}></InformationButton>
                        <InformationButton
                            title="Automatic"
                            description="Use BLASTN to determine an archetype which archetype best matches your test sequence"
                            onClick={() => handleInputMethodClick(2)}
                            selected={selectedInputMethod === 2}></InformationButton>
                    </div>
                </div>

                <div className="archetype-input">
                    <h1 className="title">Archetype Input</h1>
                    {(() => {
                        switch (selectedInputMethod) {
                            case 0: {
                                return (
                                    <div className="reference-input">
                                        <div className="selector-col">
                                            <SequenceSelector
                                                sequences={databaseArchetypes}
                                                callback={setArchetype}
                                                selectedIndex={databaseIndex}
                                                selectedIndexCallback={setDatabaseIndexCallback}></SequenceSelector>
                                        </div>
                                        <div className="input-col">
                                            <TextBox text={'>' + selectedArchetype.name + '\n' + selectedArchetype.sequence} readOnly={true}></TextBox>
                                        </div>
                                    </div>
                                );
                            }
                            case 1: {
                                return (
                                    <div className="custom-input">
                                        <div className="input-col">
                                            <TextBox
                                                placeHolder={'Input archetype sequence in FASTA format here'}
                                                persistantText={customArchetypeSequence}
                                                callback={(e) => {
                                                    setCustomArchetype(e);
                                                }}></TextBox>
                                        </div>
                                    </div>
                                );
                            }
                            case 2: {
                                return (
                                    <div className="automatic-input">
                                        {!isBlastSearch ? (
                                            <div className="search-screen">
                                                <div className="input-col">
                                                    <TextBox
                                                        placeHolder={'Input a test sequence in FASTA format here'}
                                                        persistantText={blastSearchSequence}
                                                        callback={(e) => {
                                                            blastSearchSequence = e;
                                                        }}></TextBox>
                                                </div>
                                                <div className="search-button">
                                                    <Button
                                                        text="Search"
                                                        onClick={() => {
                                                            doBlastSearch();
                                                        }}></Button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="output-screen">
                                                <div className="back-button">
                                                    <Button
                                                        text="Back"
                                                        onClick={() => {
                                                            setIsBlastSearch(false);
                                                            blastIndex = 0;
                                                            persistentIsBlastSearch = false;
                                                        }}></Button>
                                                </div>
                                                <div className="output-col">
                                                    <div className="selector-col">
                                                        <SequenceSelector
                                                            sequences={store.getState().blastSequences}
                                                            callback={setArchetype}
                                                            isBlast
                                                            selectedIndex={blastIndex}
                                                            selectedIndexCallback={setBlastIndexCallback}></SequenceSelector>
                                                    </div>
                                                    <div className="input-col">
                                                        <TextBox
                                                            text={'>' + selectedArchetype.name + '\n' + selectedArchetype.sequence}
                                                            readOnly={true}></TextBox>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            default: {
                                return 'Error';
                            }
                        }
                    })()}
                </div>
            </div>
        </div>
    );
}

export default ArchetypeInput;
