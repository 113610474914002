import {React, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import store from '../../store';
import PaperButton from '../PaperButton/PaperButton';
import './ArchetypeTable.scss';
import TextBox from '../TextBox/TextBox';

const columns = [
    {id: 'name', label: 'Sequence (Archetype)', minWidth: 30, maxWidth: 30},
    {
        id: 'length',
        label: 'Length',
        minWidth: 30,
        maxWidth: 30,
        align: 'left',
    },
    {
        id: 'fasta',
        label: 'FASTA',
        minWidth: 100,
        maxWidth: 100,
        align: 'left',
    },
];

function createData(name, length, fasta) {
    return {
        name,
        length,
        fasta,
    };
}

let rows = [];

export default function ArchetypeTable() {
    let archetypes = store.getState()['archetypes'];

    //setLocation(store.getState().location)

    if (archetypes) {
        if (archetypes.length !== 0) {
            // clear previous data first
            rows = [];
            archetypes.forEach((archetype) => {
                let fastaDiv = (
                    <div className="archetype-fasta">
                        <p>{'>' + archetype.name}</p>
                        <p>{archetype.sequence}</p>
                    </div>
                );
                rows.push(createData(archetype.name, archetype.sequence.length, fastaDiv));
            });
        }
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="archetype-table-container-class">
            <Paper sx={{width: '100%', overflow: 'hidden', border: '0.1rem solid black', borderRadius: '0.25rem'}}>
                <TableContainer
                    sx={{
                        maxHeight: '75vh',
                        minHeight: '75vh',
                        '& .MuiTableRow-root:hover': {
                            backgroundColor: '#ffffff',
                        },
                    }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
