import React, {useState, useEffect, useRef} from 'react';
import './AlignmentScreen.scss';
import Button from '../Button/Button';
import SequenceAlignment from '../SequenceAlignment/SequenceAlignment';
import SettingsBar from '../SettingsBar/SettingsBar';
import SequenceInformation from '../SequenceInformation/SequenceInformation';
import store from '../../store';

let isFirstRender = true;
let previousVisabilityToggle;
function AlignmentScreen({visibilityToggle}) {
    const [isOpen, setIsOpen] = useState(false);
    const alignmentScreenRef = useRef(null);

    function togglePopUp() {
        if (alignmentScreenRef.current.className === 'alignment-screen') {
            alignmentScreenRef.current.className = 'alignment-screen open';
            setIsOpen(true);
        } else {
            alignmentScreenRef.current.className = 'alignment-screen';
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
            previousVisabilityToggle = visibilityToggle;
        } else {
            if (previousVisabilityToggle !== visibilityToggle) {
                previousVisabilityToggle = visibilityToggle;
                togglePopUp();
            }
        }
    }, [visibilityToggle]);

    return (
        <div className="alignment-screen" ref={alignmentScreenRef}>
            {(() => {
                if (isOpen) {
                    return (
                        <div className="content-container">
                            <div className="buttons-row">
                                <div className="back-button">
                                    <Button
                                        text="Back"
                                        onClick={() => {
                                            store.dispatch({type: 'progress/setAlignmentScreenToggle', payload: !store.getState().alignmentScreenToggle});
                                        }}></Button>
                                </div>

                                <div className="spacer"></div>

                                <div className="switch-button">
                                    <Button
                                        text="Switch to Tabular"
                                        onClick={() => {
                                            store.dispatch({type: 'progress/setAlignmentScreenToggle', payload: !store.getState().alignmentScreenToggle});
                                            store.dispatch({type: 'progress/setTabularScreenToggle', payload: !store.getState().tabularScreenToggle});
                                        }}></Button>
                                </div>
                            </div>

                            <div className="alignment-row">
                                <div className="alignment-col">
                                    <SequenceAlignment></SequenceAlignment>
                                    <div className="legend-row">
                                        <div>
                                            <span className="archetype-index">■</span> Archetype Index
                                        </div>
                                        <div>
                                            <span className="sequence-index">■</span> Sequence Index
                                        </div>
                                    </div>
                                </div>
                                <div className="settings-col">
                                    <SettingsBar></SettingsBar>
                                </div>
                            </div>

                            <div className="information-row">
                                <SequenceInformation></SequenceInformation>
                            </div>
                        </div>
                    );
                }
            })()}
        </div>
    );
}

export default AlignmentScreen;
