import React, {useEffect, useRef, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {styled} from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextBox from '../TextBox/TextBox';
import Button from '../Button/Button';
import axios from 'axios';
import './ContactScreen.scss';

const BpIcon = styled('span')(({theme}) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    outline: 'auto',
    backgroundColor: '#f5f8fa',

    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#000000',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },

    'input:hover ~ &': {
        backgroundColor: '#000000',
    },
});

// Inspired by blueprintjs
function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

let isFirstRender = true;
function ContactScreen({visibilityToggle}) {
    const [queryType, setQueryType] = useState('feedback');
    const [userText, setUserText] = useState('');

    const reportBugScreenRef = useRef(null);

    async function toggleScreen() {
        if (reportBugScreenRef.current.className === 'contact-screen-container') {
            reportBugScreenRef.current.className = 'contact-screen-container open';
        } else {
            reportBugScreenRef.current.className = 'contact-screen-container';
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
        } else {
            toggleScreen();
        }
    }, [visibilityToggle]);

    function handleChange(event) {
        setQueryType(event.target.value);
    }

    function submitQuery() {
        axios.post(`/api/v1/post_contact_query`, {query_type: queryType, query_text: userText}).then((response) => {
            toggleScreen();
        });
    }

    return (
        <div className="contact-screen-container" ref={reportBugScreenRef}>
            <div className="content-container-contact-screen">
                <div className="close-button-container" onClick={() => toggleScreen()}>
                    <CloseIcon fontSize="large" classes={{root: 'close-icon'}} />
                </div>

                <h1>Contact Us</h1>

                <div className="text">
                    <div className="radio-buttons">
                        {/*feedback/report a bug/report missing data/other*/}
                        <FormControl>
                            <RadioGroup
                                value={queryType}
                                onChange={(e) => handleChange(e)}
                                defaultValue="feedback"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios">
                                <FormControlLabel value="feedback" control={<BpRadio />} label="Feedback" />
                                <FormControlLabel value="report a bug" control={<BpRadio />} label="Report a bug" />
                                <FormControlLabel value="report missing data" control={<BpRadio />} label="Report missing data" />
                                <FormControlLabel value="other" control={<BpRadio />} label="Other" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="text-box">
                        <TextBox
                            placeHolder={'Input details here'}
                            persistantText={userText}
                            callback={(e) => {
                                setUserText(e);
                            }}></TextBox>
                    </div>

                    <div className="submit-button-row">
                        <div className="spacer"></div>
                        <Button
                            text="Submit"
                            onClick={() => {
                                submitQuery(queryType, userText);
                            }}></Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactScreen;
