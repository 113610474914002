import React, {useEffect, useRef} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './AboutScreen.scss';

let isFirstRender = true;
function AboutScreen({visibilityToggle}) {
    const aboutScreenRef = useRef(null);

    async function toggleScreen() {
        if (aboutScreenRef.current.className === 'about-screen-container') {
            aboutScreenRef.current.className = 'about-screen-container open';
        } else {
            aboutScreenRef.current.className = 'about-screen-container';
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
        } else {
            toggleScreen();
        }
    }, [visibilityToggle]);

    return (
        <div className="about-screen-container" ref={aboutScreenRef}>
            <div className="about-screen-content">
                <div className="close-button-container" onClick={() => toggleScreen()}>
                    <CloseIcon fontSize="large" classes={{root: 'close-icon'}} />
                </div>

                <h1>About</h1>

                <div className="text">
                    <p>
                        FRAST stands for the Fungicide Resistance Allele Screening Tool. Its purpose is to identify known fungicide resistance mutations in a
                        selection of relevant fungal proteins, and to report them in the standardised format described in Mair W, Lopez-Ruiz F, Stammler G,
                        Clark W, Burnett F, Hollomon D, Ishii H, Thind TS, Brown JK, Fraaije B, Cools H, Shaw M, Fillinger S, Walker AS, Mellado E, Schnabel G,
                        Mehl A, Oliver RP. Proposal for a unified nomenclature for target-site mutations associated with resistance to fungicides. Pest Manag
                        Sci. 2016 Aug;72(8):1449-59. doi: 10.1002/ps.4301.
                        <br />
                        <br />
                        FRAST uses MAFFT (Katoh, Standley 2013 (Molecular Biology and Evolution 30:772-780) to align user-input sequences to selected
                        "archetype" of a corresponding fungicide resistance protein. The coordinates of mutations are reported in terms of the archetypes
                        coordinates for consistency. Where possible, FRAST provides links to known fungicide-resistance mutations.
                        <br />
                        <br />
                        It was initially conceived in 2021 as a 3rd year Curtin University Computer Science student project under the supervision of Dr James
                        Hane, Dr Fran Lopez Ruiz, and Dr Wesley Mair of the Centre for Crop and Disease Management and Prof. Richard Oliver. FRAST was further
                        developed by Mr Jackson Greene under the supervision of Dr James Hane in 2022, funded by the Curtin University School of Molecular and
                        Life Sciences Summer Scholarships scheme.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutScreen;
