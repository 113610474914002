import axios from 'axios';
import React, {useState, useEffect, useRef} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './SessionsScreen.scss';
import store from '../../store';
import Button from '../Button/Button';

let isFirstRender = true;
function SessionsScreen({visibilityToggle}) {
    const [currentDate, setCurrentDate] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [sessionName, setSessionName] = useState('');
    const [sessions, setSessions] = useState([]);

    const sessionsScreenRef = useRef(null);

    async function toggleSessionHistoryPopUp() {
        if (store.getState().isLoggedIn) {
            let date = new Date();
            let day = String(date.getDate()).padStart(2, '0');
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let year = String(date.getFullYear());
            let hours = String(date.getHours()).padStart(2, '0');
            let minutes = String(date.getMinutes()).padStart(2, '0');

            let dateString = `${day}/${month}/${year}`;
            let timeString = `${hours}:${minutes}`;
            setCurrentDate(dateString);
            setCurrentTime(timeString);

            //need to get sesions from the backend but for now just using default state to set
            //const response = await axios.get('api/v1/get_session_history');
            setSessions(store.getState().savedSessions);

            try {
                const response = await axios.get('api/v1/get_session_history');
                store.dispatch({type: 'account/setSavedSessions', payload: response.data.sessions});
                setSessions(response.data.sessions);
            } catch (error) {
                //setAlertCallback('A server error occurred', true);
            }

            if (sessionsScreenRef.current.className === 'sessions-screen') {
                sessionsScreenRef.current.className = 'sessions-screen open';
            } else {
                sessionsScreenRef.current.className = 'sessions-screen';
            }
        }
    }

    async function saveCurrentSession() {
        try {
            let sessionNameAuto = sessionName.length === 0 ? `Session ${sessions.length + 1}` : sessionName;
            let newSession = {
                name: sessionNameAuto,
                date: currentDate,
                time: currentTime,
                archetype: store.getState().archetype,
                input_string: store.getState().fastaString,
            };

            await axios.post('api/v1/post_save_session', newSession);
            let response = await axios.get('api/v1/get_session_history');
            store.dispatch({type: 'account/setSavedSessions', payload: response.data.sessions});
            setSessions(response.data.sessions);
            //setAlertCallback(`Saved ${sessionNameAuto}`, false);
        } catch (error) {
            //setAlertCallback('Max session storage reached', true);
        }
    }

    function restoreSession(index) {
        console.log(sessions[index]['archetype']);
        console.log(sessions[index]['input_string']);
        store.dispatch({
            type: 'sequences/setCustomArchetype',
            payload: `>${sessions[index]['archetype']['name']}\n${sessions[index]['archetype']['sequence']}`,
        });
        store.dispatch({
            type: 'progress/setAlignmentScreenToggle',
            payload: false,
        });
        store.dispatch({
            type: 'progress/setTabularScreenToggle',
            payload: false,
        });
        store.dispatch({
            type: 'progress/setMetadataScreenToggle',
            payload: false,
        });
        store.dispatch({type: 'sequences/setArchetype', payload: sessions[index]['archetype']});
        store.dispatch({type: 'sequences/setFastaString', payload: sessions[index]['input_string']});
        store.dispatch({type: 'sequences/setArchetypeInputMethod', payload: 1});
        store.dispatch({type: 'progress/setLocation', payload: {step: 2, subStep: 0}});
        toggleSessionHistoryPopUp();
    }

    async function deleteSession(index) {
        try {
            await axios.post('api/v1/delete_session_from_history', String(index));
            let response = await axios.get('api/v1/get_session_history');
            store.dispatch({type: 'account/setSavedSessions', payload: response.data.sessions});
            setSessions(response.data.sessions);
            //setAlertCallback(`Deleted ${sessions[index]['name']}`, false);
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
        } else {
            toggleSessionHistoryPopUp();
        }
    }, [visibilityToggle]);

    return (
        <div className="sessions-screen" ref={sessionsScreenRef}>
            <div className="content-container-sessions-screen">
                <div className="close-button-container" onClick={() => toggleSessionHistoryPopUp()}>
                    <CloseIcon fontSize="large" classes={{root: 'close-icon'}} />
                </div>

                <h1>Sessions</h1>

                <h2>Current Session</h2>
                <div className="session-save-row">
                    <div className="session-name row-text">
                        <input
                            placeholder={sessions ? `Session ${sessions.length + 1}` : 'Session 1'}
                            value={sessionName}
                            onChange={(e) => setSessionName(e.target.value)}
                        />
                    </div>
                    <div className="session-archetype row-text">{store.getState().archetype ? store.getState().archetype.name : 'none'}</div>
                    <div className="session-date row-text">{currentDate}</div>
                    <div className="session-date row-text">{currentTime}</div>

                    <div className="session-delete-button-container button-col">
                        <Button text="Delete" />
                    </div>
                    <div className="session-save-button-container button-col">
                        <Button
                            onClick={() => {
                                saveCurrentSession();
                            }}
                            text="Save"
                        />
                    </div>
                </div>

                <h2>Saved Sessions</h2>
                {sessions &&
                    sessions.map((session, index) => {
                        return (
                            <div className="session-row">
                                <div className="session-name row-text">{session.name}</div>
                                <div className="session-archetype row-text">{session.archetype ? session.archetype.name : 'none'}</div>
                                <div className="session-date row-text">{session.date}</div>
                                <div className="session-date row-text">{session.time}</div>

                                <div className="session-delete-button-container button-col">
                                    <Button
                                        onClick={() => {
                                            deleteSession(index);
                                        }}
                                        text="Delete"
                                    />
                                </div>
                                <div className="session-restore-button-container button-col">
                                    <Button
                                        onClick={() => {
                                            restoreSession(index);
                                        }}
                                        text="Restore"
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export default SessionsScreen;
