import React from 'react';
import './InputBox.scss';

function InputBox({ text, placeHolder, readOnly, callback }) {
    return (
        <div className="input-box-container">
            <input
                className="text"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                readOnly={readOnly}
                value={text}
                defaultValue={text}
                placeholder={placeHolder}
                onChange={e => callback(e.target.value)}
            ></input>
        </div>
    );
}

export default InputBox;
