import React from 'react';
import './SettingsBar.scss';
import store from '../../store';
import SettingsCheckBox from './SettingsCheckBox';
import SettingsInput from './SettingsInput';

function SettingsBar() {
    let settings = store.getState().alignmentSettings;

    function settingsCallback(data) {
        settings = {
            ...settings,
            data,
        };
        store.dispatch({ type: 'sequences/setAlignmentSettings', payload: settings });
    }

    return (
        <div className="settings-bar-container">
            <div className="output-settings">
                <h2>Output Filters</h2>

                <div className="filter-section-title">General</div>

                <div className="filter-section-container">
                    <SettingsCheckBox setting={settings.showPositionNumbers} settingsCallback={settingsCallback} />
                    <SettingsCheckBox setting={settings.showSequenceNames} settingsCallback={settingsCallback} />
                    <SettingsCheckBox setting={settings.indexRange} settingsCallback={settingsCallback} />
                    <div className="index-range-input-container output-sub-settings">
                        <SettingsInput placeholder={1} min={0} max={10000} setting={settings.indexRangeValueMin} settingsCallback={settingsCallback} />
                        <div className="separator">-</div>
                        <SettingsInput placeholder={100} min={0} max={10000} setting={settings.indexRangeValueMax} settingsCallback={settingsCallback} />
                    </div>
                </div>

                <div className="filter-section-title">Mutations</div>

                <div className="filter-section-container">
                    <SettingsCheckBox setting={settings.showKnownMutations} settingsCallback={settingsCallback} />
                    <SettingsCheckBox setting={settings.showUnknownMutations} settingsCallback={settingsCallback} />

                    <SettingsCheckBox setting={settings.surroundingCharacters} settingsCallback={settingsCallback} />
                    <div className="output-sub-settings">
                        <SettingsInput placeholder={0} min={0} max={10000} setting={settings.surroundingCharactersValue} settingsCallback={settingsCallback} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsBar;
