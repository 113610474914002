import React, {useState} from 'react';
import './SequenceInformation.scss';
import store from '../../store';
import PaperButton from '../PaperButton/PaperButton';

function SequenceInformation() {
    const [mutationState, setMutationState] = useState(null);
    const [startIndexArchetype, setStartIndexArchetype] = useState(0);
    const [endIndexArchetype, setEndIndexArchetype] = useState(0);

    store.subscribe(() => {
        if (store.getState()['selectedMutation']) {
            let selectedMutation = store.getState()['selectedMutation'];
            let indexRaw = selectedMutation['archetypeCharacterDatas'].find((characterData) => {
                return characterData['indexArchetype'] === selectedMutation['mutation']['index_archetype'];
            })['indexRaw'];

            let endIndexArchetypeTemp = 0;
            let startIndexArchetypeTemp = 0;
            startIndexArchetypeTemp = Math.max(indexRaw - 5, 1);
            endIndexArchetypeTemp = Math.min(indexRaw + selectedMutation['mutation']['length'] + 5, selectedMutation['archetypeCharacterDatas'].length);

            if (selectedMutation['mutation']['type'] === 'insertion') {
                let endIndexOffset = 0;
                let counter = 1;
                while (selectedMutation['sequenceCharacterDatas'][indexRaw - 1 + counter]['character'] === '-') {
                    endIndexOffset += 1;
                    counter += 1;
                }

                endIndexArchetypeTemp = Math.min(endIndexArchetypeTemp + endIndexOffset, selectedMutation['archetypeCharacterDatas'].length);
            }

            if (selectedMutation['mutation']['type'] === 'swap') {
                endIndexArchetypeTemp = Math.min(endIndexArchetypeTemp - 1, selectedMutation['archetypeCharacterDatas'].length);
            }

            setStartIndexArchetype(startIndexArchetypeTemp);
            setEndIndexArchetype(endIndexArchetypeTemp);
            setMutationState(selectedMutation);
        }
    });

    return (
        <div className="info-container">
            <div className="main-col-container">
                <div className="mutation-information-col info-col">
                    <h2>Mutation Information</h2>
                    <div className="info-row">
                        <span>Sequence: </span> <div className="data">{mutationState ? mutationState['sequenceName'] : '-'}</div>
                    </div>
                    <div className="info-row">
                        <span>Archetype Index: </span> <div className="data">{mutationState ? mutationState['mutation']['index_archetype'] : '-'}</div>
                    </div>
                    <div className="info-row">
                        <span>Sequence Index: </span> <div className="data">{mutationState ? mutationState['mutation']['index_sequence'] : '-'}</div>
                    </div>
                    <div className="info-row">
                        <span>Code: </span> <div className="data">{mutationState ? mutationState['mutation']['code'] : '-'}</div>
                    </div>
                </div>

                <div className="sequence-comparision-col info-col">
                    <h2>Sequence Comparison</h2>
                    <div className="info-row">
                        <span className="sequence-label-span">Archetype: </span>{' '}
                        <div className="data">
                            {mutationState && (
                                <div className="sequence">
                                    {console.log(startIndexArchetype - 1)}
                                    {console.log(endIndexArchetype)}
                                    {mutationState['archetypeCharacterDatas'].slice(startIndexArchetype - 1, endIndexArchetype + 1 - 1).map((characterData) => {
                                        let positionNumberArchetypeClass = `${characterData['indexArchetype'] !== null ? ' ' : 'hidden'}`;

                                        return (
                                            <div className={`sequence-character-container`}>
                                                <p className="character-container">{characterData.character}</p>
                                                <p className={`position-number-archetype ${positionNumberArchetypeClass}`}>
                                                    {characterData['indexArchetype'] ? characterData['indexArchetype'] : '-'}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="info-row">
                        <span className="sequence-label-span">Sequence: </span>{' '}
                        <div className="data">
                            {mutationState && (
                                <div className="sequence">
                                    {mutationState['sequenceCharacterDatas'].slice(startIndexArchetype - 1, endIndexArchetype + 1 - 1).map((characterData) => {
                                        let mutationClass = `${characterData['isMutation'] && !characterData['isKnown'] ? 'unknown-mutation' : ''} ${
                                            characterData['isMutation'] && characterData['isKnown'] ? 'known-mutation' : ''
                                        }`;

                                        let positionNumberSequenceClass = `${characterData['indexSequence'] !== null ? ' ' : 'hidden'}`;
                                        return (
                                            <div className={`sequence-character-container ${mutationClass}`}>
                                                <p className="character-container">{characterData.character}</p>
                                                <p className={`position-number-sequence ${positionNumberSequenceClass}`}>
                                                    {characterData['indexSequence'] ? characterData['indexSequence'] : '-'}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="papers-col-container">
                <div className="research-papers-col info-col">
                    <h2>Research Papers</h2>
                    <div className="papers-list">
                        {mutationState && mutationState['mutation']['papers'].length > 0
                            ? mutationState['mutation']['papers'].map((paper) => {
                                  //console.log(paper);
                                  return <PaperButton text={paper['abr']} link={paper['find_at']}></PaperButton>;
                              })
                            : '-'}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SequenceInformation;
