import {React, useState, useEffect} from 'react';
import './Results.scss';
import store from '../../store';
import InformationBox from '../InformationBox/InformationBox';
import InputBox from '../InputBox/InputBox';
import Button from '../Button/Button';
import axios from 'axios';

function Results() {
    const [isSaved, setIsSaved] = useState(false);
    const [sessions, setSessions] = useState(store.getState().savedSessions);

    useEffect(() => {
        try {
            axios.get('api/v1/get_session_history').then((response) => {
                store.dispatch({type: 'account/setSavedSessions', payload: response.data.sessions});
                setSessions(response.data.sessions);
            });
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
        }
    }, []);

    store.subscribe(() => {
        setSessions(store.getState().savedSessions);
    });

    let sessionName = sessions ? `Session ${sessions.length + 1}` : 'Session Name';

    let date = new Date();
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = String(date.getFullYear());
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');

    let dateString = `${day}/${month}/${year}`;
    let timeString = `${hours}:${minutes}`;

    async function saveCurrentSession() {
        let sessions = store.getState().savedSessions;
        try {
            let sessionNameAuto = sessionName.length === 0 ? `Session ${sessions.length + 1}` : sessionName;
            let newSession = {
                name: sessionNameAuto,
                date: dateString,
                time: timeString,
                archetype: store.getState().archetype,
                input_string: store.getState().fastaString,
            };

            await axios.post('api/v1/post_save_session', newSession);
            let response = await axios.get('api/v1/get_session_history');
            store.dispatch({type: 'account/setSavedSessions', payload: response.data.sessions});
            setIsSaved(true);
            //setSessions(response.data.sessions);
            //setAlertCallback(`Saved ${sessionNameAuto}`, false);
        } catch (error) {
            //setAlertCallback('Max session storage reached', true);
        }
    }

    return (
        <div className="sequence-output-container">
            <InformationBox text={'Choose if you would like to save your session so that it can be restored later in the sessions menu'}></InformationBox>

            <div className="sequence-output-content">
                <div className="save-session">
                    <h1 className="title">Save Session</h1>
                    <div className="save-inputs">
                        <InputBox placeHolder={sessionName} callback={(e) => (sessionName = e)}></InputBox>
                        <InputBox text={dateString} readOnly={true}></InputBox>
                        <InputBox text={timeString} readOnly={true}></InputBox>
                    </div>
                    <div className="save-button-row">
                        <div className="save-button">
                            <Button text="Save" onClick={() => (isSaved ? '' : saveCurrentSession())}></Button>
                        </div>
                        <p class={isSaved ? '' : 'hidden'}>Session saved</p>
                        <div className="spacer"></div>
                    </div>
                    <p className="terms-text">
                        By saving your session you agree to our <a href="www.google.com">Terms and Conditions</a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Results;
