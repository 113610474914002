import React from 'react';
import './PaperButton.scss';

function PaperButton({text, link}) {
    return (
        <a href={link} target="_blank" rel="noreferrer" className="paper-button-container">
            <h3 className="text">{text}</h3>
            <img className="link-icon" src="images/external_link.svg" alt="" srcset="" />
        </a>
    );
}

export default PaperButton;
