import React from 'react';
import './Button.scss';

function Button({onClick, text, icon, dark, disabled = false}) {
    return (
        <div
            className="button-container"
            style={{
                background: disabled ? '#fafafa' : dark ? '#000000' : '#ffffff',
                color: disabled ? '#aaaaaa' : dark ? '#ffffff' : '#000000',
                border: disabled ? 'solid 0.15rem #cfcfcf' : 'solid 0.15rem #000000',
            }}
            onClick={!disabled && onClick}>
            {(() => {
                if (!icon) {
                    return (
                        <>
                            <h3 className="text">{text}</h3>
                        </>
                    );
                } else {
                    return (
                        <>
                            <h3 className="text">{text}</h3>
                            <div className="icon">{icon}</div>
                        </>
                    );
                }
            })()}
        </div>
    );
}

export default Button;
