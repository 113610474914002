import React, {useState, useEffect} from 'react';
import {Checkbox, FormControlLabel, Typography} from '@material-ui/core';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function SettingsCheckBox({setting, settingsCallback}) {
    const {height, width} = useWindowDimensions();
    const [checked, setChecked] = useState(setting.value);

    let smallScreen = false;
    if (width < 1400) {
        smallScreen = true;
    }

    const handleClick = () => {
        setChecked(!checked);
        setting.value = !checked;
        settingsCallback(setting);
    };

    useEffect(() => {
        setChecked(setting.value);
    }, [setting]);

    return (
        <div>
            <FormControlLabel
                checked={checked}
                control={
                    <Checkbox
                        onClick={handleClick}
                        color="default"
                        style={smallScreen ? {color: '#000000', transform: 'scale(0.825)'} : {color: '#000000', transform: 'scale(0.9)'}}
                    />
                }
                label={<Typography style={smallScreen ? {fontSize: '0.75rem'} : {fontSize: '0.85rem'}}>{setting.label}</Typography>}
            />
        </div>
    );
}

export default SettingsCheckBox;
