import {React, useState} from 'react';
import './SequenceSelector.scss';

function SequenceSelector({sequences, callback, isBlast = false, selectedIndex = 0, selectedIndexCallback}) {
    const [selectedSequenceIndex, setSelectedSequenceIndex] = useState(selectedIndex);

    function handleSequenceClick(index) {
        selectedIndexCallback(index);
        setSelectedSequenceIndex(index);
        callback(sequences[index]);
    }

    return (
        <div className="sequence-selector-container">
            <div className="selected-sequence">
                {sequences.length > 0 && sequences[selectedSequenceIndex] && (
                    <div className="sequence-name selected">{sequences[selectedSequenceIndex].name}</div>
                )}
            </div>
            <div className="all-sequences-container">
                <div className="headers-row">
                    <p className="sequence-name-header">Name</p>
                    <div className="sequence-stats-header" style={{visibility: !isBlast ? 'hidden' : ''}}>
                        <p>Similarity (Length)</p>
                    </div>
                </div>
                {sequences.length > 0 && (
                    <div className="all-sequences">
                        {!isBlast
                            ? sequences.map((sequence, index) => {
                                  return (
                                      <div className="sequence-row-container" onClick={() => handleSequenceClick(index)}>
                                          <div className={'sequence-name ' + (selectedSequenceIndex === index ? 'selected' : '')}>{sequence.name}</div>
                                      </div>
                                  );
                              })
                            : sequences.map((sequence, index) => {
                                  return (
                                      <div className="sequence-row-container" onClick={() => handleSequenceClick(index)}>
                                          <div className={'sequence-name ' + (selectedSequenceIndex === index ? 'selected' : '')}>{sequence.name}</div>
                                          <div className={'sequence-stats ' + (selectedSequenceIndex === index ? 'selected' : '')}>{`${parseFloat(
                                              sequence.similarity,
                                          ).toFixed(2)}% (${sequence.length})`}</div>
                                      </div>
                                  );
                              })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SequenceSelector;
