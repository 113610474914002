import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import store from '../../store';
import CloseIcon from '@material-ui/icons/Close';
import './LoginScreen.scss';
import Button from '../Button/Button';

let isFirstRender = true;
function LoginScreen({ visibilityToggle }) {
    const LoginScreenRef = useRef(null);

    async function toggleSessionHistoryPopUp() {
        if (LoginScreenRef.current.className === 'login-screen') {
            LoginScreenRef.current.className = 'login-screen open';
        } else {
            LoginScreenRef.current.className = 'login-screen';
        }
    }

    async function googleSignInButtonClicked() {
        try {
            window.location = 'login/google';
            console.log('reee');
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
        }
    }

    async function microsoftSignInButtonClicked() {
        try {
            window.location = 'login/microsoft';
            //store.dispatch({ type: 'account/setIsLoggedIn', payload: true });
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
        } else {
            toggleSessionHistoryPopUp();
        }
    }, [visibilityToggle]);

    return (
        <div className="login-screen" ref={LoginScreenRef}>
            <div className="content-container-login-screen">
                <div className="close-button-container" onClick={() => toggleSessionHistoryPopUp()}>
                    <CloseIcon fontSize="large" classes={{ root: 'close-icon' }} />
                </div>

                <img className="nav-logo" src="images/frast_logo.svg" alt="" />

                <div className="login-buttons">
                    <Button text="Login with Google" onClick={() => googleSignInButtonClicked()}></Button>
                    <Button text="Login with Microsoft" onClick={() => microsoftSignInButtonClicked()}></Button>
                </div>

                <p>
                    By using this website, you agree with our{' '}
                    <a href="http://www.google.com" target="_blank" rel="noreferrer">
                        Terms and Conditions
                    </a>
                </p>
            </div>
        </div>
    );
}

export default LoginScreen;
