import {React} from 'react';
import './TextBox.scss';

function TextBox({text, persistantText, placeHolder, readOnly, callback}) {
    return (
        <div className="text-box-container">
            <textarea
                className="text"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                readOnly={readOnly}
                value={text}
                defaultValue={persistantText}
                placeholder={placeHolder}
                onChange={(e) => callback(e.target.value)}></textarea>
        </div>
    );
}

export default TextBox;
