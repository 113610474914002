import { React, useState } from 'react'
import './Progress.scss'
import store from '../../store'

function Progress() {
    const [currentLocation, setCurrentLocation] = useState(store.getState().location);

    store.subscribe(() => setCurrentLocation(store.getState().location));

    function setLocation(step, subStep){
        store.dispatch({type: "progress/setLocation", payload: {step: step, subStep: subStep}})
    }

    return (
        <div className="container">

            <div className="step">
              <div className="visual-column">
                <img className={"triangle " + (currentLocation.step !== 0 ? "hidden" : "")} src="images/triangle.svg" alt="" />
                {(currentLocation.step >= 1) ? 
                <><img className="circle" src="images/progress_circle_1.svg" alt="" />
                <img className="line" src="images/progress_line_1.svg" alt="" /></> : 
                <><img className="circle" src="images/progress_circle_0.svg" alt="" />
                <img className="line" src="images/progress_line_0.svg" alt="" /></>}
              </div>
              <div className="text-column">
                <div className="title" onClick={() => {setLocation(0, 0)}}>
                  <h2>Archetype Input</h2>
                </div>
                <div className="sub-titles">
                  <h3>Reference</h3>
                  <h3>Custom</h3>
                  <h3>Automatic</h3>
                </div>
              </div>
            </div>

            <div className="step">
              <div className="visual-column">
                <img className={"triangle " + (currentLocation.step !== 1 ? "hidden" : "")} src="images/triangle.svg" alt="" />
                {(currentLocation.step >= 2) ? 
                <><img className="circle" src="images/progress_circle_1.svg" alt="" />
                <img className="line" src="images/progress_line_1.svg" alt="" /></> : 
                <><img className="circle" src="images/progress_circle_0.svg" alt="" />
                <img className="line" src="images/progress_line_0.svg" alt="" /></>}
              </div>
              <div className="text-column">
                <div className="title" onClick={() => {setLocation(1, 0)}}>
                  <h2>Sequence Input</h2>
                </div>
                <div className="sub-titles">
                  <h3>Manual</h3>
                  <h3>Upload</h3>
                </div>
              </div>
            </div>

            <div className="step">
              <div className="visual-column">
                <img className={"triangle " + (currentLocation.step !== 2 ? "hidden" : "")} src="images/triangle.svg" alt="" />
                {(currentLocation.step >= 3) ? 
                <><img className="circle" src="images/progress_circle_1.svg" alt="" />
                <img className="line" src="images/progress_line_1.svg" alt="" /></> : 
                <><img className="circle" src="images/progress_circle_0.svg" alt="" />
                <img className="line" src="images/progress_line_0.svg" alt="" /></>}
              </div>
              <div className="text-column">
                <div className="title" onClick={() => {setLocation(2, 0)}}>
                  <h2>Sequence Output</h2>
                </div>
                <div className="sub-titles">
                  <h3>Alignment</h3>
                  <h3>Tabular</h3>
                </div>
              </div>
            </div>

            <div className="step">
              <div className="visual-column">
                <img className={"triangle " + (currentLocation.step !== 3 ? "hidden" : "")} src="images/triangle.svg" alt="" />
                <img className="circle" src="images/progress_circle_0.svg" alt="" />
              </div>
              <div className="text-column">
                <div className="title" onClick={() => {setLocation(3, 0)}}>
                  <h2>Results</h2>
                </div>
                <div className="sub-titles">
                  <h3>Session</h3>
                </div>
              </div>
            </div>

          </div>
    )
}

export default Progress
