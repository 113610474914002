import {React, useState, useEffect} from 'react';
import './SequenceAlignment.scss';
import store from '../../store';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {ScrollSync, ScrollSyncPane} from 'react-scroll-sync';

function SequenceAlignment() {
    const [archetypeSequence, setArchetypeSequence] = useState([]);
    const [testSequences, setTestSequences] = useState([]);
    const [hiddenSequences, setHiddenSequences] = useState([]);
    let allMutationIndexesRaw = [];
    let archetypeCharacterDatas = [];
    generateArchetypeCharacterDatas();
    let sequencesCharacterDatas = [];
    sequencesCharacterDatas = generateSequencesCharacterDatas();

    let alignedSequences = store.getState()['alignedSequences'];
    let settings = store.getState()['alignmentSettings'];

    //console.log(allMutationIndexesRaw);

    store.subscribe(() => {
        alignedSequences = store.getState()['alignedSequences'];
        settings = store.getState()['alignmentSettings'];
        if (Object.keys(alignedSequences).length !== 0) {
            const alignedSequencesData = alignedSequences['sequences'];
            setArchetypeSequence(alignedSequencesData[0]);
            setTestSequences(alignedSequencesData.slice(1, alignedSequencesData.length));
            generateArchetypeCharacterDatas();
        }
    });

    function toggleHideSequence(index) {
        if (hiddenSequences.includes(index)) {
            for (let i = 0; i < hiddenSequences.length; i += 1) {
                if (hiddenSequences[i] === index) {
                    hiddenSequences.splice(i, 1);
                    i -= 1;
                }
            }
        } else {
            hiddenSequences.push(index);
        }
        setHiddenSequences([...hiddenSequences]);
    }

    function moveSequenceUp(index) {
        if (index > 0) {
            if (!(hiddenSequences.includes(index) && hiddenSequences.includes(index - 1))) {
                if (hiddenSequences.includes(index)) {
                    // Check if the hidden sequences contains either sequence that is being swapped
                    // Change the index's so the correct sequence is hidden
                    const currentIndex = hiddenSequences.indexOf(index);

                    if (currentIndex > -1) {
                        hiddenSequences.splice(currentIndex, 1);
                    }
                    hiddenSequences.push(index - 1);
                } else if (hiddenSequences.includes(index - 1)) {
                    const currentIndex = hiddenSequences.indexOf(index - 1);

                    if (currentIndex > -1) {
                        hiddenSequences.splice(currentIndex, 1);
                    }
                    hiddenSequences.push(index);
                }
            }

            let newTestSequences = [...testSequences];
            const currentSequence = testSequences[index];
            const previousSequence = testSequences[index - 1];
            newTestSequences[index] = previousSequence;
            newTestSequences[index - 1] = currentSequence;
            setTestSequences(newTestSequences);
        }
    }

    function moveSequenceDown(index) {
        if (index < testSequences.length - 1) {
            if (!(hiddenSequences.includes(index) && hiddenSequences.includes(index + 1))) {
                // Check if the hidden sequences contains either sequence that is being swapped
                // Change the index's so the correct sequence is hidden
                if (hiddenSequences.includes(index)) {
                    const currentIndex = hiddenSequences.indexOf(index);

                    if (currentIndex > -1) {
                        hiddenSequences.splice(currentIndex, 1);
                    }
                    hiddenSequences.push(index + 1);
                } else if (hiddenSequences.includes(index + 1)) {
                    const currentIndex = hiddenSequences.indexOf(index + 1);

                    if (currentIndex > -1) {
                        hiddenSequences.splice(currentIndex, 1);
                    }
                    hiddenSequences.push(index);
                }
            }

            let newTestSequences = [...testSequences];
            const currentSequence = testSequences[index];
            const nextSequence = testSequences[index + 1];

            newTestSequences[index] = nextSequence;
            newTestSequences[index + 1] = currentSequence;

            setTestSequences(newTestSequences);
        }
    }

    // function getAllMutationIndexes() {
    //     const mutationsIndexList = [];
    //     testSequences.forEach((sequence) => {
    //         const mutations = sequence['mutations'];
    //         mutations.forEach((mutation) => {
    //             mutationsIndexList.push(mutation['index_archetype']);
    //         });
    //     });

    //     return mutationsIndexList;
    // }

    function isInIndexRange(index) {
        return (
            !settings.indexRange.value ||
            (settings.indexRange.value && index + 1 >= settings.indexRangeValueMin.value && index <= settings.indexRangeValueMax.value)
        );
    }

    function isSurroundingCharacter(index) {
        //index is the raw index of the character (1 based and including any gaps or mutations)
        if (!settings.surroundingCharacters.value) {
            return true;
        }

        //index is raw index (so 1 based not 0 based)
        let minIndex = index - parseInt(settings.surroundingCharactersValue.value, 10);
        let maxIndex = index + parseInt(settings.surroundingCharactersValue.value, 10);

        for (let i = minIndex; i <= maxIndex; i += 1) {
            if (allMutationIndexesRaw.includes(i)) {
                //this is for optimisation, rather than continue just return if it is already known to be true
                return true;
            }
        }

        return false;
    }

    function generateArchetypeCharacterDatas() {
        if (archetypeSequence.length !== 0) {
            let indexArchetypeOffset = 0;

            const characterDatas = Array.from(archetypeSequence['sequence_data']).map((character, index) => {
                let isInsertedGap = false;
                if (character === '-') {
                    indexArchetypeOffset += 1;
                    isInsertedGap = true;
                }

                let indexRaw = index + 1;
                let indexArchetype = indexRaw - indexArchetypeOffset;

                return {
                    character: character,
                    indexRaw: indexRaw,
                    indexArchetype: isInsertedGap ? null : indexArchetype,
                };
            });

            archetypeCharacterDatas = characterDatas;
        } else {
            archetypeCharacterDatas = [];
        }
    }

    //calculate all the indicies for each character here
    //that way can just display relevant number rather than calculating later
    //calculate the mutation location by simply checking the mutation code indicies
    //with the ones calculated earlier
    function generateSequenceCharacterDatas(sequence) {
        let indexSequenceOffset = 0;
        let mutations = sequence['mutations'];
        let highlightedRawIndicies = [];
        const characterDatas = Array.from(sequence['sequence_data']).map((character, index) => {
            let isInsertedGap = false;
            if (character === '-') {
                indexSequenceOffset += 1;
                isInsertedGap = true;
            }

            let indexRaw = index + 1;
            let indexSequence = indexRaw - indexSequenceOffset;
            let isMutation = false;
            let isKnown = false;

            mutations.forEach((mutation) => {
                if (mutation['index_archetype'] === archetypeCharacterDatas[index]['indexArchetype']) {
                    // isMutation = true;

                    // if (mutation['papers'].length > 0) {
                    //     isKnown = true;
                    // }

                    let offset = 0;
                    let tempIndex = index + 1;
                    if (mutation['type'] === 'insertion') {
                        while (sequence['sequence_data'][tempIndex] === '-') {
                            tempIndex += 1;
                            offset += 1;
                        }
                    }

                    let startIndex;
                    let endIndex;

                    if (mutation['type'] === 'insertion') {
                        startIndex = index + 1 + offset;
                        endIndex = index + mutation['length'] + offset;
                    } else if (mutation['type'] === 'deletion') {
                        startIndex = index;
                        endIndex = index + mutation['length'] - 1;
                    } else if (mutation['type'] === 'swap') {
                        startIndex = index;
                        endIndex = index;
                    } else {
                        startIndex = index;
                        endIndex = index + mutation['length'];
                    }

                    for (let i = startIndex; i <= endIndex; i++) {
                        highlightedRawIndicies.push({index: i, mutation: mutation});
                    }
                }
            });

            return {
                character: character,
                indexRaw: indexRaw,
                indexArchetype: archetypeCharacterDatas[index]['indexArchetype'],
                indexSequence: isInsertedGap ? null : indexSequence,
                isMutation: isMutation,
                mutation: null,
                isKnown: isKnown,
            };
        });

        highlightedRawIndicies.forEach((indexMutationData) => {
            let index = indexMutationData['index'];
            allMutationIndexesRaw.push(index + 1);

            let mutation = indexMutationData['mutation'];

            characterDatas[index]['isMutation'] = true;

            characterDatas[index]['mutation'] = indexMutationData['mutation'];

            if (mutation['papers'].length > 0) {
                characterDatas[index]['isKnown'] = true;
            }
        });

        return characterDatas;
    }

    function generateSequencesCharacterDatas() {
        return testSequences.map((sequence) => {
            return generateSequenceCharacterDatas(sequence);
        });
    }

    //want the cropped character data for both sequence and archetype
    //5 characters each side of the mutation
    //raw index (remember raw index starts at 1 not 0) where mutation starts and where mutation ends
    function handleMutationClick(sequenceName, sequenceCharacterDatas, archetypeName, archetypeCharacterDatas, mutation) {
        // mutation, sequenceName, archetypeCharacters, sequenceCharacters
        //const mutationData = sequence['mutations'].find((mutation) => parseInt(mutation['index_archetype'], 10) === indexArchetype);
        if (mutation) {
            let newMutation = {
                mutation: mutation,
                archetypeName: archetypeName,
                archetypeCharacterDatas: archetypeCharacterDatas,
                sequenceName: sequenceName,
                sequenceCharacterDatas: sequenceCharacterDatas,
            };
            store.dispatch({type: 'sequences/setSelectedMutation', payload: newMutation});
        }
    }

    return (
        <ScrollSync>
            <div style={alignedSequences.sequences ? {} : {visibility: 'hidden'}}>
                <div className="sequence-alignment-container">
                    {
                        // to allow for horizontal scrolling of just the sequence,
                        // there are 2 rows, one for the name and icons, one for the sequence
                    }

                    <div className="names-col-container">
                        {
                            // archetype row is first
                        }
                        <div className="archetype-name-col-row-container">
                            <VisibilityIcon style={{visibility: 'hidden'}} fontSize="small" classes={{root: 'visability-icon'}} />
                            <ArrowDropUpIcon style={{visibility: 'hidden'}} fontSize="small" classes={{root: 'up-icon'}} />
                            <ArrowDropDownIcon style={{visibility: 'hidden'}} fontSize="small" classes={{root: 'down-icon'}} />
                            <div className="name" style={!settings.showSequenceNames.value ? {visibility: 'hidden'} : {}}>
                                {archetypeSequence.name}
                            </div>
                        </div>

                        <ScrollSyncPane group={['vertical']}>
                            <div className="sequences-names-whole-container">
                                {
                                    // go through each sequence and name and icons
                                }
                                {testSequences.map((sequence, index) => {
                                    const {name} = sequence;
                                    return (
                                        <div className="names-col-row-container">
                                            <VisibilityIcon
                                                fontSize="small"
                                                classes={{root: 'visability-icon'}}
                                                onClick={() => {
                                                    toggleHideSequence(index);
                                                }}
                                            />
                                            <ArrowDropUpIcon
                                                fontSize="small"
                                                classes={{root: 'up-icon'}}
                                                onClick={() => {
                                                    moveSequenceUp(index);
                                                }}
                                            />
                                            <ArrowDropDownIcon
                                                fontSize="small"
                                                classes={{root: 'down-icon'}}
                                                onClick={() => {
                                                    moveSequenceDown(index);
                                                }}
                                            />
                                            <div className="name" style={!settings.showSequenceNames.value ? {visibility: 'hidden'} : {}}>
                                                {name}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </ScrollSyncPane>
                    </div>

                    <div className="sequences-col-container">
                        <ScrollSyncPane group={['horizontal']}>
                            <div className="archetype-sequence-whole-container">
                                {
                                    // archetype row is first
                                    // there will only be 1 archetype but
                                    // the map is to prevent rending on no input
                                    // in the future better solution can be used
                                }
                                <div className="sequences-col-row-container archetype-sequence-col-row-container">
                                    <div className="sequence">
                                        {archetypeCharacterDatas.map((characterData) => {
                                            if (isInIndexRange(characterData['indexRaw'])) {
                                                let positionShown = settings.showPositionNumbers.value && characterData['character'] !== '-';

                                                if (isSurroundingCharacter(characterData['indexRaw'])) {
                                                    return (
                                                        <div className="sequence-character-container">
                                                            <p className={`position-number-archetype ${positionShown ? ' ' : 'hidden'}`}>
                                                                {characterData['indexArchetype'] ? characterData['indexArchetype'] : '-'}
                                                            </p>
                                                            <p>{characterData['character']}</p>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </ScrollSyncPane>

                        <ScrollSyncPane group={['horizontal', 'vertical']}>
                            <div className="sequences-whole-container">
                                {
                                    // go through each sequence
                                    // get the indexes for the mutations
                                    // for each character return a div and give classes
                                    // corresponding to if there is a mutation at that index
                                }
                                {testSequences.map((sequence, sequencePosition) => {
                                    //let sequenceCharacterDatas = generateSequenceCharacterDatas(sequence);
                                    let sequenceCharacterDatas = sequencesCharacterDatas[sequencePosition] ? sequencesCharacterDatas[sequencePosition] : [];

                                    let isHidden = true;
                                    if (hiddenSequences.includes(sequencePosition)) {
                                        isHidden = true;
                                    } else {
                                        isHidden = false;
                                    }

                                    return (
                                        <div className={`sequences-col-row-container ${isHidden ? 'hidden' : ' '}`}>
                                            <div className="sequence">
                                                {sequenceCharacterDatas.map((characterData) => {
                                                    if (isInIndexRange(characterData['indexRaw'])) {
                                                        if (isSurroundingCharacter(characterData['indexRaw'])) {
                                                            let mutationClass = `${
                                                                characterData['isMutation'] && !characterData['isKnown'] && settings.showUnknownMutations.value
                                                                    ? 'unknown-mutation'
                                                                    : ''
                                                            } ${
                                                                characterData['isMutation'] && characterData['isKnown'] && settings.showKnownMutations.value
                                                                    ? 'known-mutation'
                                                                    : ''
                                                            }`;

                                                            let positionNumberArchetypeClass = `${
                                                                settings.showPositionNumbers.value && characterData['indexArchetype'] !== null ? ' ' : 'hidden'
                                                            }`;

                                                            let positionNumberSequenceClass = `${
                                                                settings.showPositionNumbers.value && characterData['indexSequence'] !== null ? ' ' : 'hidden'
                                                            }`;
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        //sequenceName, sequenceCharacterDatas, archetypeName, archetypeCharacterDatas, mutation
                                                                        handleMutationClick(
                                                                            sequence['name'],
                                                                            sequenceCharacterDatas,
                                                                            store.getState()['archetype']['name'],
                                                                            archetypeCharacterDatas,
                                                                            characterData['mutation'],
                                                                        );
                                                                    }}
                                                                    className={`sequence-character-container ${mutationClass}`}>
                                                                    <p className={`position-number-archetype ${positionNumberArchetypeClass}`}>
                                                                        {characterData['indexArchetype'] ? characterData['indexArchetype'] : '-'}
                                                                    </p>
                                                                    <p className="character-container">{characterData.character}</p>
                                                                    <p className={`position-number-sequence ${positionNumberSequenceClass}`}>
                                                                        {characterData['indexSequence'] ? characterData['indexSequence'] : '-'}
                                                                    </p>
                                                                </div>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </ScrollSyncPane>
                    </div>
                </div>
            </div>
        </ScrollSync>
    );
}

export default SequenceAlignment;
