import {React, useState, useEffect} from 'react';
import './App.scss';
import store from './store';
import Button from './components/Button/Button';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Progress from './components/Progress/Progress';
import ArchetypeInput from './components/ArchetypeInput/ArchetypeInput';
import SequenceInput from './components/SequenceInput/SequenceInput';
import SequenceOutput from './components/SequenceOutput/SequenceOutput';
import Results from './components/Results/Results';
import axios from 'axios';
import AlertBox from './components/AlertBox/AlertBox';

function App() {
    const [location, setLocation] = useState(store.getState().location);

    store.subscribe(() => setLocation(store.getState().location));

    function incrementLocation() {
        let isError = false;
        if (location.step === 0) {
            if (store.getState().archetype === null) {
                store.dispatch({type: 'progress/setAlertErrorString', payload: 'Archetype sequence not valid'});
                isError = true;
            }
        }

        if (location.step === 1) {
            if (store.getState().archetype === null || store.getState().fastaString === null) {
                store.dispatch({type: 'progress/setAlertErrorString', payload: 'Archetype sequence or test sequences not valid'});
                isError = true;
            }
        }

        if (!isError) {
            let newLocation = {step: Math.min(location.step + 1, 3), subStep: 0};
            store.dispatch({type: 'progress/setLocation', payload: newLocation});
        }
    }

    function decrementLocation() {
        let newLocation = {step: Math.max(location.step - 1, 0), subStep: 0};
        store.dispatch({type: 'progress/setLocation', payload: newLocation});
    }

    async function checkIsLoggedIn() {
        try {
            const response = await axios.get('api/v1/check_login');
            if (response.data.logged_in) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
            return false;
        }
    }

    async function updateEmail() {
        try {
            const response = await axios.get('api/v1/get_email');
            store.dispatch({type: 'account/setAccountEmail', payload: response.data.email});
        } catch (error) {
            //setAlertCallback('A server error occurred', true);
        }
    }

    useEffect(() => {
        checkIsLoggedIn().then((result) => {
            store.dispatch({type: 'account/setIsLoggedIn', payload: result});

            if (result) {
                updateEmail();
            }
        });
    }, []);

    return (
        <div className="main-container">
            <NavigationBar></NavigationBar>
            <div className="content-container">
                <div className="progress-column">
                    <Progress></Progress>
                </div>
                <div className="interface-column">
                    <div className="information-column">
                        {(() => {
                            switch (location.step) {
                                case 0: {
                                    return <ArchetypeInput></ArchetypeInput>;
                                }
                                case 1: {
                                    return <SequenceInput></SequenceInput>;
                                }
                                case 2: {
                                    return <SequenceOutput></SequenceOutput>;
                                }
                                case 3: {
                                    return <Results></Results>;
                                }
                                default: {
                                    return '0';
                                }
                            }
                        })()}
                    </div>
                    <div className="steps-column">
                        <div className="step-row">
                            <div className="spacer"></div>
                            <AlertBox></AlertBox>
                            <div className="step-buttons">
                                <Button text="Previous" dark onClick={() => decrementLocation()} disabled={location.step === 0}></Button>
                                <Button text="Next" dark onClick={() => incrementLocation()} disabled={location.step === 3}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
