import React from 'react'
import "./InformationBox.scss"

function InformationBox({text}) {
    return (
        <div className="information-box-container">
            <img src="images/information.svg" alt="" />
            <div className="text">
                {text}
            </div>
        </div>
    )
}

export default InformationBox
