import {React, useRef, useState} from 'react';
import './NavigationBar.scss';
import store from '../../store';
import Button from '../Button/Button';
import TutorialScreen from '../TutorialScreen/TutorialScreen';
import AboutScreen from '../AboutScreen/AboutScreen';
import ContactScreen from '../ContactScreen/ContactScreen';
import SessionsScreen from '../SessionsScreen/SessionsScreen';
import LoginScreen from '../LoginScreen/LoginScreen';
import AccountScreen from '../AccountScreen/AccountScreen';
import BrowseArchetypesScreen from '../BrowseArchetypesScreen/BrowseArchetypesScreen';
import BrowseMutationsScreen from '../BrowseMutationsScreen/BrowseMutationsScreen';

function NavigationBar() {
    const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);
    const [tutorialScreenToggle, setTutorialScreenToggle] = useState(false);
    const [aboutScreenToggle, setAboutScreenToggle] = useState(false);
    const [browseArchetypesScreenToggle, setBrowseArchetypesScreenToggle] = useState(false);
    const [browseMutationsScreenToggle, setBrowseMutationsScreenToggle] = useState(false);
    const [contactScreenToggle, setContactScreenToggle] = useState(false);
    const [sessionsScreenToggle, setSessionsScreenToggle] = useState(false);
    const [loginScreenToggle, setLoginScreenToggle] = useState(false);
    const [accountScreenToggle, setAccountScreenToggle] = useState(false);

    store.subscribe(() => setIsLoggedIn(store.getState().isLoggedIn));

    let timer = null;

    const navHelpMenuRef = useRef(null);
    const navAboutMenuRef = useRef(null);
    const navContactMenuRef = useRef(null);
    const navBrowseMenuRef = useRef(null);

    let menusRefs = [navHelpMenuRef, navAboutMenuRef, navContactMenuRef, navBrowseMenuRef];

    function handleMouseEnter(navMenuRef) {
        clearTimeout(timer);
        menusRefs.forEach((menuRef) => {
            menuRef.current.className = 'drop-down-menu';
        });
        navMenuRef.current.className = 'drop-down-menu open';
    }

    function handleMouseLeave(navMenuRef) {
        timer = setTimeout(() => {
            navMenuRef.current.className = 'drop-down-menu';
        }, 150);
    }

    function accountButtonClicked() {
        if (store.getState().isLoggedIn) {
            setAccountScreenToggle(!accountScreenToggle);
        } else {
            setLoginScreenToggle(!loginScreenToggle);
        }
    }

    function sessionsButtonClicked() {
        if (store.getState().isLoggedIn) {
            setSessionsScreenToggle(!sessionsScreenToggle);
        } else {
            //setAlertCallback('Login to use this feature', true);
        }
    }

    return (
        <div>
            <TutorialScreen visibilityToggle={tutorialScreenToggle}></TutorialScreen>
            <AboutScreen visibilityToggle={aboutScreenToggle}></AboutScreen>
            <BrowseArchetypesScreen visibilityToggle={browseArchetypesScreenToggle}></BrowseArchetypesScreen>
            <BrowseMutationsScreen visibilityToggle={browseMutationsScreenToggle}></BrowseMutationsScreen>
            <ContactScreen visibilityToggle={contactScreenToggle}></ContactScreen>
            <SessionsScreen visibilityToggle={sessionsScreenToggle}></SessionsScreen>
            <LoginScreen visibilityToggle={loginScreenToggle}></LoginScreen>
            <AccountScreen visibilityToggle={accountScreenToggle}></AccountScreen>
            <nav className="nav-bar">
                <img
                    className="nav-logo"
                    src="images/frast_logo.svg"
                    alt=""
                    onClick={() => store.dispatch({type: 'progress/setLocation', payload: {step: 0, subStep: 0}})}
                />
                <div className="nav-headers">
                    <div className="nav-text" onMouseEnter={() => handleMouseEnter(navHelpMenuRef)} onMouseLeave={() => handleMouseLeave(navHelpMenuRef)}>
                        <img src="images/triangle.svg" alt="" />
                        <h2 className="text">Help</h2>
                        <div
                            className="drop-down-menu"
                            ref={navHelpMenuRef}
                            onMouseEnter={() => handleMouseEnter(navHelpMenuRef)}
                            onMouseLeave={() => handleMouseLeave(navHelpMenuRef)}>
                            <h3
                                onClick={() => {
                                    setTutorialScreenToggle(!tutorialScreenToggle);
                                }}>
                                How to use FRAST
                            </h3>
                        </div>
                    </div>
                    <div className="nav-text" onMouseEnter={() => handleMouseEnter(navAboutMenuRef)} onMouseLeave={() => handleMouseLeave(navAboutMenuRef)}>
                        <img src="images/triangle.svg" alt="" />
                        <h2 className="text">About</h2>
                        <div
                            className="drop-down-menu"
                            ref={navAboutMenuRef}
                            onMouseEnter={() => handleMouseEnter(navAboutMenuRef)}
                            onMouseLeave={() => handleMouseLeave(navAboutMenuRef)}>
                            <h3
                                onClick={() => {
                                    setAboutScreenToggle(!aboutScreenToggle);
                                }}>
                                What is FRAST?
                            </h3>
                        </div>
                    </div>
                    <div className="nav-text" onMouseEnter={() => handleMouseEnter(navBrowseMenuRef)} onMouseLeave={() => handleMouseLeave(navBrowseMenuRef)}>
                        <img src="images/triangle.svg" alt="" />
                        <h2 className="text">Browse</h2>
                        <div
                            className="drop-down-menu"
                            ref={navBrowseMenuRef}
                            onMouseEnter={() => handleMouseEnter(navBrowseMenuRef)}
                            onMouseLeave={() => handleMouseLeave(navBrowseMenuRef)}>
                            <h3
                                onClick={() => {
                                    setBrowseArchetypesScreenToggle(!browseArchetypesScreenToggle);
                                }}>
                                Archetype sequences
                            </h3>
                            <h3
                                onClick={() => {
                                    setBrowseMutationsScreenToggle(!browseMutationsScreenToggle);
                                }}>
                                Known mutations
                            </h3>
                        </div>
                    </div>
                    <div className="nav-text" onMouseEnter={() => handleMouseEnter(navContactMenuRef)} onMouseLeave={() => handleMouseLeave(navContactMenuRef)}>
                        <img src="images/triangle.svg" alt="" />
                        <h2 className="text">Contact</h2>
                        <div
                            className="drop-down-menu"
                            ref={navContactMenuRef}
                            onMouseEnter={() => handleMouseEnter(navContactMenuRef)}
                            onMouseLeave={() => handleMouseLeave(navContactMenuRef)}>
                            <h3
                                onClick={() => {
                                    setContactScreenToggle(!contactScreenToggle);
                                }}>
                                Contact us
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="nav-buttons">
                    <Button
                        text="Sessions"
                        onClick={() => {
                            sessionsButtonClicked();
                        }}></Button>
                    <Button
                        text={!isLoggedIn ? 'Login' : 'Account'}
                        dark
                        onClick={() => {
                            accountButtonClicked();
                        }}></Button>
                </div>
            </nav>
        </div>
    );
}

export default NavigationBar;
