import React, {useEffect, useRef} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './BrowseMutationsScreen.scss';
import MutationsTable from '../MutationsTable/MutationsTable';

let isFirstRender = true;
function BrowseMutationsScreen({visibilityToggle}) {
    const browseMutationsScreenRef = useRef(null);

    async function toggleScreen() {
        if (browseMutationsScreenRef.current.className === 'browse-mutations-screen-container') {
            browseMutationsScreenRef.current.className = 'browse-mutations-screen-container open';
        } else {
            browseMutationsScreenRef.current.className = 'browse-mutations-screen-container';
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            isFirstRender = false;
        } else {
            toggleScreen();
        }
    }, [visibilityToggle]);

    return (
        <div className="browse-mutations-screen-container" ref={browseMutationsScreenRef}>
            <div className="content-container-browse-mutations-screen">
                <div className="close-button-container" onClick={() => toggleScreen()}>
                    <CloseIcon fontSize="large" classes={{root: 'close-icon'}} />
                </div>

                <div className="table-container">
                    <MutationsTable />
                </div>
            </div>
        </div>
    );
}

export default BrowseMutationsScreen;
