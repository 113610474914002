import React from 'react';
import './InformationButton.scss';

function InformationButton({ onClick, title, description, selected }) {
    return (
        <div className={"information-button-container " + (selected ? "selected" : "")} onClick={onClick}>
            <h2 className="title">{title}</h2>
            <h3 className="description">{description}</h3>
        </div>
    );
}

export default InformationButton;
