import {React, useState, useEffect} from 'react';
import './SequenceOutput.scss';
import store from '../../store';
import InformationBox from '../InformationBox/InformationBox';
import InformationButton from '../InformationButton/InformationButton';
import AlignmentScreen from '../AlignmentScreen/AlignmentScreen';
import TabularScreen from '../TabularScreen/TabularScreen';
import Button from '../Button/Button';
import axios from 'axios';
import FileSaver from 'file-saver';
import {RotatingLines} from 'react-loader-spinner';

function SequenceOutput() {
    const [alignmentScreenToggle, setAlignmentScreenToggle] = useState(store.getState().alignmentScreenToggle);
    const [tabularScreenToggle, setTabularScreenToggle] = useState(store.getState().tabularScreenToggle);
    const [selectedInputMethod, setSelectedInputMethod] = useState(0);
    const [outputIsLoading, setOutputIsLoading] = useState(true);
    const [outputError, setOutputError] = useState(false);

    store.subscribe(() => {
        setOutputIsLoading(store.getState().alignedSequences ? false : true);
    });

    store.subscribe(() => {
        setAlignmentScreenToggle(store.getState().alignmentScreenToggle);
        setTabularScreenToggle(store.getState().tabularScreenToggle);
    });

    useEffect(() => {
        parseSequences();
    }, []);

    async function parseSequences() {
        const postContent = {
            fastaString: store.getState().fastaString,
            archetype: store.getState().archetype,
        };

        try {
            const response = await axios.post('api/v1/post_parse_sequences', postContent);
            store.dispatch({type: 'sequences/setAlignedSequences', payload: response.data});
            setOutputError(false);
        } catch (error) {
            setOutputError(true);
            store.dispatch({type: 'progress/setAlertErrorString', payload: 'Sequence processing failed, sequences not valid'});
        }
    }

    function toggleAlignmentScreen() {
        let newAlignmentScreenToggle = !alignmentScreenToggle;
        store.dispatch({type: 'progress/setAlignmentScreenToggle', payload: newAlignmentScreenToggle});
        setAlignmentScreenToggle(newAlignmentScreenToggle);
    }

    function toggleTabularScreen() {
        let newTabularScreenToggle = !tabularScreenToggle;
        store.dispatch({type: 'progress/setTabularScreenToggle', payload: newTabularScreenToggle});
        setTabularScreenToggle(newTabularScreenToggle);
    }

    function handleInputMethodClick(index) {
        store.dispatch({type: 'progress/setLocation', payload: {step: 2, subStep: index}});
        setSelectedInputMethod(index);
    }

    function downloadMafftOutput() {
        let date = new Date();
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = String(date.getFullYear());
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');

        let dateString = `${day}-${month}-${year}`;
        let timeString = `${hours}-${minutes}`;
        var blob = new Blob([store.getState()['alignedSequences']['mafft_output']], {type: 'text/plain;charset=utf-8'});
        FileSaver.saveAs(blob, `mafft_output ${store.getState().archetype ? store.getState().archetype.name : 'none'} ${dateString + ' ' + timeString}.fasta`);
    }

    function generateCsvString() {
        let outputString = 'archetype_sequence,test_sequence,mutation_code,is_known,index_archetype,index_sequence,paper_abbreviation,paper_references\n';
        let sequences = store.getState()['alignedSequences']['sequences'];
        let archetypeSequence = store.getState()['archetype']['sequence'];

        sequences.forEach((sequence) => {
            sequence['mutations'].forEach((mutation) => {
                let paperAbrs = [];
                let paperRefs = [];

                mutation['papers'].forEach((mutationPaper) => {
                    paperAbrs.push(mutationPaper['abr']);
                    paperRefs.push(mutationPaper['find_at']);
                });

                let csvRow = `${archetypeSequence},${sequence['sequence_data']},${mutation['code']},${
                    mutation['papers'].length > 0 ? 'true' : 'false'
                },${String(mutation['index_archetype'])},${String(mutation['index_sequence'])},${paperAbrs.join(';')},${paperRefs.join(';')}\n`;
                outputString += csvRow;
            });
        });

        return outputString;
    }

    function downloadCsvOutput() {
        let date = new Date();
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = String(date.getFullYear());
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');

        let dateString = `${day}-${month}-${year}`;
        let timeString = `${hours}-${minutes}`;

        var blob = new Blob([generateCsvString()], {type: 'text/plain;charset=utf-8'});
        FileSaver.saveAs(blob, `csv_output ${store.getState().archetype ? store.getState().archetype.name : 'none'} ${dateString + ' ' + timeString}.csv`);
    }

    return (
        <div className="sequence-output-container">
            <AlignmentScreen visibilityToggle={alignmentScreenToggle}></AlignmentScreen>
            <TabularScreen visibilityToggle={tabularScreenToggle}></TabularScreen>
            <InformationBox text={'Choose a method to ouput the data generated from the processing of the sequences'}></InformationBox>

            <div className="sequence-output-content">
                <div className="output-method">
                    <h1 className="title">Output Method</h1>
                    {outputIsLoading ? (
                        !outputError && (
                            <div style={{height: '12.5rem'}}>
                                <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                            </div>
                        )
                    ) : (
                        // <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                        <div className="output-method-buttons">
                            <InformationButton
                                title="Alignment"
                                description="Align the sequences in pairwise alignments with the archetype sequence"
                                onClick={() => handleInputMethodClick(0)}
                                selected={selectedInputMethod === 0}></InformationButton>
                            <InformationButton
                                title="Tabular"
                                description="Generate a table of all mutations found and any relevant references"
                                onClick={() => handleInputMethodClick(1)}
                                selected={selectedInputMethod === 1}></InformationButton>
                        </div>
                    )}
                </div>

                <div className="sequence-output" style={{visibility: outputError || outputIsLoading ? 'hidden' : 'visible'}}>
                    <h1 className="title">Sequence Output</h1>
                    {(() => {
                        switch (selectedInputMethod) {
                            case 0: {
                                return (
                                    <div className="alignment-output">
                                        <div className="buttons-col">
                                            <Button
                                                text="View Output"
                                                onClick={() => {
                                                    toggleAlignmentScreen();
                                                }}></Button>

                                            <Button text="Download MAFFT Output" onClick={() => downloadMafftOutput()}></Button>
                                        </div>
                                        <div className="spacer-col"></div>
                                    </div>
                                );
                            }
                            case 1: {
                                return (
                                    <div className="tabular-output">
                                        <div className="buttons-col">
                                            <Button
                                                text="View Output"
                                                onClick={() => {
                                                    toggleTabularScreen();
                                                }}></Button>
                                            <Button text="Download CSV Output" onClick={() => downloadCsvOutput()}></Button>
                                        </div>
                                        <div className="spacer-col"></div>
                                    </div>
                                );
                            }
                            default: {
                                return 'Error';
                            }
                        }
                    })()}
                </div>
            </div>
        </div>
    );
}

export default SequenceOutput;
